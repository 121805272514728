<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active" aria-current="page">製作團隊</li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2 class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3">
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold">團隊列表</span>
              </h2>
            </div>
            <table
              class="table table-striped table-bordered text-center lh-lg font-1-2em"
            >
              <thead>
                <tr>
                  <th class="bg-warning">職稱</th>
                  <th class="bg-warning">姓名</th>
                  <th class="bg-warning">經歷</th>
                </tr>
              </thead>
              <tbody class="table-group-divide">
                <tr>
                  <td>計畫主持人</td>
                  <td>蔡蕙頻</td>
                  <td>國立臺灣圖書館/編審兼臺灣學研究中心</td>
                </tr>
                <tr>
                  <td>共同主持人</td>
                  <td>楊靖宇</td>
                  <td>東南科技大學/研發長</td>
                </tr>
                <tr>
                  <td>共同主持人</td>
                  <td>陳浩然</td>
                  <td>新北市立樟樹國際實創高級中等學校/校長</td>
                </tr>
                <tr>
                  <td>共同主持人</td>
                  <td>詹雅能</td>
                  <td>東南科技大學/通識教育中心/副教授</td>
                </tr>
                <tr>
                  <td>共同主持人</td>
                  <td>張美春</td>
                  <td>東南科技大學/數位媒體設計系/助理教授</td>
                </tr>
                <tr>
                  <td>團隊成員</td>
                  <td>林惠娟</td>
                  <td>東南科技大學/通識教育中心/副教授</td>
                </tr>
                <tr>
                  <td>團隊成員</td>
                  <td>詹 瑋</td>
                  <td>東南科技大學/通識教育中心/副教授</td>
                </tr>
                <tr>
                  <td>團隊成員</td>
                  <td>林文仁</td>
                  <td>東南科技大學/通識教育中心/助理教授</td>
                </tr>
                <tr>
                  <td>團隊成員</td>
                  <td>張志祥</td>
                  <td>東南科技大學/圖資長/助理教授</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
